import store from '@/store'
import { computed, onMounted, ref } from '@vue/composition-api'

export default function useAnalysisTransactions() {
  const unfilteredPortfolioTransactions = ref([])
  const unfilteredStaffTransactions = ref([])
  const loading = ref(false)
  const companies = ref(['Company 1', 'Company 2', 'Company 3'])
  const selectedCompany = ref('')

  const selectedPortfolio = ref('')
  const selectedStaff = ref('')

  const headers = [
    { text: 'Name', value: 'name' },
    { text: 'Security', value: 'security' },
    { text: 'Exchange', value: 'exchange' },
    { text: 'Execution Date', value: 'date' },
  ]

  const analysisHeaders = [
    { text: 'Security', value: 'security' },
    { text: 'Number Of Trades', value: 'number-of-trades' },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const fetchPortfolioTransactions = () => {
    store
      .dispatch('pa-dealing/fetchPortfolioTransactions')
      .then(data => {
        unfilteredPortfolioTransactions.value = data
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  const fetchStaffTransactions = () => {
    store
      .dispatch('pa-dealing/fetchStaffTransactions')
      .then(data => {
        unfilteredStaffTransactions.value = data.docs
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  onMounted(() => {
    loading.value = true
    fetchPortfolioTransactions()
    fetchStaffTransactions()
  })

  // *====================---*
  // *------- UI -------------------*
  // *====================---*

  const portfolioTransactions = computed(() => {
    return unfilteredPortfolioTransactions.value.filter(val => val.name === selectedPortfolio.value)
  })

  const totalPortfolioTransactions = computed(() => portfolioTransactions.value.length)

  const staffTransactions = computed(() => {
    return unfilteredStaffTransactions.value.filter(
      val => val.user && val.user._id && val.user._id === selectedStaff.value,
    )
  })

  const totalStaffTransactions = computed(() => staffTransactions.value.length)

  const portfolioOPtions = computed(() => {
    return unfilteredPortfolioTransactions.value.map(val => val.name)
  })

  const staffOPtions = computed(() => {
    return unfilteredStaffTransactions.value
      .filter(val => val.user && val.user._id)
      .map(val => {
        const { firstname, surname } = val.user.user
        return { label: `${firstname || ''} ${surname || ''}`, value: val.user._id }
      })
  })

  return {
    headers,
    analysisHeaders,
    portfolioTransactions,
    staffTransactions,
    totalPortfolioTransactions,
    totalStaffTransactions,
    companies,
    selectedCompany,
    loading,
    portfolioOPtions,
    staffOPtions,
    selectedPortfolio,
    selectedStaff,
  }
}
