<template>
  <div>
    <ac-module-heading> Transactions Analysis </ac-module-heading>
    <v-card class="card-wrapper">
      <v-card-text class="d-flex align-center pb-5">
        <ac-select
          label="Company"
          v-model="selectedCompany"
          :items="companies"
          class="select-width me-3"
        />
      </v-card-text>
      <div class="d-flex">
        <dir class="w-50">
          <span class="ml-5 font-weight-bold text-h5">Portfolio Transactions</span>
          <ac-select
            label="Portfolio"
            v-model="selectedPortfolio"
            :items="portfolioOPtions"
            class="select-width ma-5 mt-2"
          />
          <v-data-table
            v-if="portfolioTransactions.length"
            :headers="headers"
            :items="portfolioTransactions"
            :server-items-length="totalPortfolioTransactions"
            :loading="loading"
            class="text-no-wrap"
          />
        </dir>
        <v-spacer />
        <dir class="w-50">
          <span class="ml-5 font-weight-bold text-h5">Personal Transactions</span>
          <ac-select
            label="User"
            v-model="selectedStaff"
            :items="staffOPtions"
            class="select-width ma-5 mt-2"
          />
          <v-data-table
            v-if="staffTransactions.length"
            :headers="headers"
            :items="staffTransactions"
            :server-items-length="totalStaffTransactions"
            :loading="loading"
            class="text-no-wrap"
          />
        </dir>
      </div>
      <div class="mt-5" v-if="portfolioTransactions.length && staffTransactions.length">
        <span class="ml-5 font-weight-bold text-h5">Number Of Trades</span>
        <v-data-table
          :headers="analysisHeaders"
          :items="portfolioTransactions"
          :server-items-length="totalPortfolioTransactions"
          class="text-no-wrap"
        >
          <template #[`item.number-of-trades`]>
            <span>0</span>
          </template>

          <template #[`item.actions`]>
            <ac-icon-btn text="View" :icon="icons.mdiEyeOutline" />
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script>
import AcSelect from '@/components/AcSelect.vue'
import AcIconBtn from '@/components/AcIconBtn.vue'
import store from '@/store'
import padealingStoreModule from '@/store/modules/pa-dealing'
import { mdiDeleteOutline, mdiDownloadOutline, mdiEyeOutline, mdiPlus } from '@mdi/js'
import { onUnmounted } from '@vue/composition-api'
import useAnalysisTransactions from './composition-apis/transactions-analysis'

export default {
  components: {
    AcSelect,
    AcIconBtn,
  },
  setup() {
    const PA_DEALING_STORE_MODULE_NAME = 'pa-dealing'

    // Register module
    if (!store.hasModule(PA_DEALING_STORE_MODULE_NAME)) {
      store.registerModule(PA_DEALING_STORE_MODULE_NAME, padealingStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PA_DEALING_STORE_MODULE_NAME))
        store.unregisterModule(PA_DEALING_STORE_MODULE_NAME)
    })

    const {
      headers,
      analysisHeaders,
      portfolioTransactions,
      staffTransactions,
      totalPortfolioTransactions,
      totalStaffTransactions,
      companies,
      selectedCompany,
      loading,
      portfolioOPtions,
      staffOPtions,
      selectedPortfolio,
      selectedStaff,
    } = useAnalysisTransactions()

    return {
      headers,
      analysisHeaders,
      portfolioTransactions,
      staffTransactions,
      totalPortfolioTransactions,
      totalStaffTransactions,
      companies,
      selectedCompany,
      loading,
      portfolioOPtions,
      staffOPtions,
      selectedPortfolio,
      selectedStaff,

      icons: { mdiDeleteOutline, mdiDownloadOutline, mdiEyeOutline, mdiPlus },
    }
  },
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  .select-width {
    max-width: 16rem;
  }
  .staff-list-search {
    max-width: 12rem;
  }
}

.w-50 {
  width: 48%;
}
</style>
